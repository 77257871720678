#features {
    background-color: #ffff;
}

.features {
    margin-left: 20px;

}
#features-top {
    font-weight: bold;
    font-size: 35px;
}

#features-top span {
    font-weight: bold;
    font-size: 35px;
}

#neex-features {
    font-size: 50px;
}
