div #neex {
    background-color: #09b688;
    background-image: url(../img/AdobeStock_522905508.png);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: auto;
    background-attachment: fixed;
}

#neex-top {
    color: white;
    font-weight: bold;
    font-size: 35px;
    border-bottom: solid white;
    border-width: 100%;
    margin-left: 20px;
}

#neex-top span {
    color: #FFF131;
}

#neex-title {
    font-size: 50px;
}

#box {
    margin-left: 20px;
}

#text {
    font-size: 25px;
    font-weight: bold;
}

.neex {
    margin-top: 45%;
}
