#recruitment {
    background-color: #00CF97;
}

.recruitment {
    position: relative;
    background-color: #fff;
    opacity: 0.8;
    background: linear-gradient(white, white, #95EBD4, #00CF97);
}

#recruitment-top {
    font-weight: bold;
    font-size: 35px;
}

#recruitment-text {
    font-size: 18px;
}

h4 {
    font-weight: bolder;
}

.img-recruitment {
    object-fit: cover;
    width: 100%;
    height: 100%;
  
}