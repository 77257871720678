.topImages {
    position: fixed;
    z-index: -1;
}

.neex-name {
    background-color: white;
    width: 350px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.top-text {
    position: absolute;
    top: 25%;
    left: 230px;
    z-index: 2;
}

#company-name {
    font-size: 70px;
    font-weight: bolder;
    border-bottom: solid #09b688;
}

.top-text {
    text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3) ,
    -2px 2px 10px rgba(255, 255, 255, 0.3) ,
    2px -2px 10px rgba(255, 255, 255, 0.3) ,
    -2px -2px 10px rgba(255, 255, 255, 0.3);
}

.top-text span {
    color: #09b688;
}

.md-top-text {
    position: absolute;
    top: 20%;
    left: 10px;
    z-index: 2;
    text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3) ,
    -2px 2px 10px rgba(255, 255, 255, 0.3) ,
    2px -2px 10px rgba(255, 255, 255, 0.3) ,
    -2px -2px 10px rgba(255, 255, 255, 0.3);
}

#md-company-name {
    font-size: 40px;
    font-weight: bolder;
    border-bottom: solid #09b688;
}

#md-company-copy {
    font-size: 15px;
}

.md-top-text span {
    color: #09b688;
}
