#company {
    background-color: rgb(255, 255, 255);
}

.company-top {
    font-weight: bold;
    font-size: 35px;
}

#neex-company {
    /* font-size: 35px; */
}
#company-history {
    /* font-weight: bold;
    font-size: 35px; */
}

#company-text {
    /* font-size: 18px; */
}
