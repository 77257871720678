#products {
    background-color: #FDFFBA;
    background-image: url(../img/img-products.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: auto;
    background-attachment: fixed;
}

#products-top {
    font-weight: bold;
    font-size: 35px;
}

#neex-products {
    font-size: 50px;
}

#products-text {
    font-size: 18px;
}
