@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover{
  cursor: pointer;
  /* max-width: 300px; */
  width: 100%;
}

.hover {
  height: auto;
  transition: transform .6s ease;
}
.hover:hover {
  transform: scale(1.1);
}

.section {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.paralax {
  width: 100%;
  height: 600px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.paralax01 {
  background-image: url('../img/top_images_08.png');
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.paralax02 {
  background-image: url('../img/top_images_10.png');
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.modal-dialog {
  width: 100%;
}

h3 {
  font-weight: bold;
  font-size: 40px;
}

p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  font-weight: bold;

}
